@import "src/assets/styles/colors";
@import "src/assets/styles/table";

// textarea {
//   display: block;
//   width: 100%;
//   height: calc(1.5em + 0.75rem + 2px);
//   padding: 0rem 0.75rem;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: $cinza-text;
//   background-color: $branco;
//   background-clip: padding-box;
//   border: 0px;
//   resize: none !important;
// }

// buttons
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
  cursor: pointer;
}

.btn-blue {
  color: $branco !important;
  background-color: #8cb6c9 !important;
  border-color: #8cb6c9 !important;
  border-radius: 4px !important;
  margin: 2% 0;
}

.btn-blue:hover:enabled {
  background-color: $azul-ativo !important;
}

.btn-green {
  color: $branco !important;
  background-color: lightgreen !important;
  border-color: lightgreen !important;
  border-radius: 4px !important;
  margin: 2% 0;
}

.btn-green:hover:enabled {
  background-color: green !important;
}

.btn-red {
  color: $branco !important;
  background-color: tomato !important;
  border-color: tomato !important;
  border-radius: 4px !important;
  margin: 2% 0;
}

.btn-red:hover:enabled {
  background-color: red !important;
}

.btn-white {
  color: $preto !important;
  background-color: $branco !important;
  border-color: $cinza-border !important;
  border-radius: 4px !important;
  margin: 2% 0;
}

.btn-white:hover:enabled {
  background-color: $cinza-hover !important;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}

.disabled {
  pointer-events: none;
  opacity: 0.65 !important;
  cursor: not-allowed !important;
}

a:disabled {
  cursor: not-allowed;
}

a {
  background-color: transparent;
}

.label-btn {
  padding: 10px 20px;
  margin-top: 0;
  height: 100%;
  cursor: pointer;
}

.label-btn:hover {
  background-color: $azul-ativo !important;
}

// end buttons

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.mat-radio-outer-circle {
  .mat-radio-checked & {
    border-color: $azul-base !important;
  }
}

.mat-radio-inner-circle {
  background-color: $azul-base !important;
}

.mat-radio-ripple .mat-ripple-element {
  background-color: rgba($azul-base, 0.26) !important;
}

fieldset {
  border: 1px solid $cinza-border;
  border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  border-radius: 0.25rem;
  padding: 0px 5px 5px 5px;
}

legend {
  width: auto !important;
  font-size: 0.8rem !important;
  margin-left: 5px;
  padding: 0px 5px 0px 5px;
}

// file upload
//input[type="file"] {
//  display: none;
//}

.btn-situacao-ativo {
  color: $branco !important;
  background-color: $azul-base !important;
  border-color: $azul-base !important;
  border-radius: 4px !important;
  padding: 0px;
  margin: 2% 0;
  font-style: normal;
  font-size: 12px;
}

.btn-situacao-inativo {
  color: $preto !important;
  background-color: $branco !important;
  border-color: $cinza-border !important;
  border-radius: 4px !important;
  padding: 0px;
  margin: 2% 0;
  font-style: normal;
  font-size: 12px;
}

label {
  //font-weight: bold;
  margin-bottom: 5px;
}

.required {
  color: red;
  font-weight: bold;
  padding-left: 2px;
}
