$azul-base: #046fae;
$azul-ativo: #065c8d;
$azul-hover-grid: #dcf1fa;
$azul-icone:#0086bf;
$azul-botao: #337ab7;
$azul-header-table:#47b6e4;
$branco: #ffffff;
$preto: #000000;
$cinza-base: #B7B6BB;
$cinza-claro: #ededed;
$cinza-hover: #d8d8d8;
$cinza-text: #495057;
$cinza-border: #ced4da;
