@use "@angular/material" as mat;
@import "/src/assets/styles/colors.scss";
@import "/src/assets/styles/spinner.scss";
@import "/src/assets/styles/form-controls.scss";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "@angular/material/theming";
@import "../node_modules/@angular/material/theming";

//@include mat.core();

@font-face {
  font-family: Poppins;
  src: url(assets/fontes/Poppins/Poppins-Regular.ttf) format("opentype");
}

@font-face {
  font-family: Roboto;
  src: url(assets/fontes/Roboto/Roboto-Regular.ttf) format("opentype");
}

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

body {
  background-color: #ffffff !important;
  font-size: 14px !important;
  font-family: Poppins;
}

.form-control {
  border: 2px solid #14142b;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 6px !important;
}

.semborda {
  border: 0px transparent !important;
  background-color: transparent !important;
  font-family: Poppins;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 28px;
}

.main {
  min-height: 100vh;
}

.content {
  margin-top: 20px;
  //margin-left: 210px;
}

.center {
  text-align: center !important;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.padding-top {
  padding-top: 15px;
}

.magin-left {
  margin-left: 15px;
}

.content-page {
  background-color: $branco;
  padding-bottom: 15px;
}

.content-title {
  background-color: $branco;
  color: #5f5f5f;
  height: 40px;
  padding-top: 10.5px;
  margin-bottom: 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
}

.title-description {
  margin-bottom: 10px;
  padding: 5px 0px 5px 0px;
}

.white-text {
  color: $branco;
}

label {
  margin-bottom: 0 !important;
}

.breadcrumb {
  padding-left: 0rem !important;
  padding: 0.75rem 1rem;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.background-breadcrumb {
  background-color: $branco !important;
  font-style: normal;
  font-size: 12px;
}

.w70 {
  width: 70%;
}

.w05 {
  width: 3%;
}

.w15 {
  width: 14%;
}

.w300 {
  width: 300px;
}

.w180 {
  width: 180px;
}

tr.mat-header-row {
  height: 40px !important;
  //background-color: $vermelho-base;
  font-weight: bold;
}

tr.mat-row {
  height: 40px;
}

::ng-deep .mat-sort-header-sorted {
  color: $branco !important;
}

::ng-deep .mat-sort-header-arrow {
  color: $branco !important;
}

$my-custom-primary-color: (
  50: #edebfc,
  100: #d3cef8,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #ffffff,
  A200: #e9e7ff,
  A400: #b9b4ff,
  A700: #a29bff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$app-primary: mat.define-palette($my-custom-primary-color);
$app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

$app-theme: mat.define-light-theme($app-primary, $app-accent);

@mixin mix-app-theme($app-theme) {
  $primary: map-get($app-theme, primary);
  $accent: map-get($app-theme, accent);

  .mat-button-toggle {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($accent, default-contrast);
  }
}

// Include the mixin
@include mix-app-theme($app-theme);

// rodapé
.ie-fixMinHeight {
  display: flex;
}

#page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#content {
  flex-grow: 1;
}

#footer {
  margin-top: 20px;
}

footer {
  padding: 1rem;
  color: $cinza-text;
  // background-color: white;
  text-align: center;
}

.recuo-div-lr {
  padding-left: 2%;
  padding-right: 2%;
}

.recuo-div {
  padding-left: 2%;
}

.recuo-div-table {
  padding-left: 1%;
}

.quebra-linha {
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #065c8d !important;
}

.mat-slide-toggle.mat-disabled {
  opacity: unset !important;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  padding: 10px !important;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.mat-mdc-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
}

.mat-mdc-form-field {
  font-size: medium;
}
.mat-mdc-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
}

.mat-mdc-form-field-flex {
  height: 1.4rem;
}

.mat-mdc-form-field-icon-suffix {
  height: 48px;
}

.mat-date-range-input {
  height: 38px;
}

.mat-mdc-form-field-infix {
  padding-top: 15px;
  height: 1.4rem;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
  .mat-mdc-form-field-infix {
  padding-top: 7px;
  padding-bottom: 8px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
.mat-mdc-form-field:focus-within {
  color: var(--bs-body-color) !important;
  background-color: transparent !important;
  border-color: #86b7fe !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.mat-mdc-form-field {
  height: 2.4rem;
  padding: 0px !important;
}

.mat-mdc-text-field-wrapper {
  height: 2.4rem !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  height: 2.4rem !important;
}

.mat-mdc-button-touch-target {
  height: 34px;
  width: 34px;
}

.mat-mdc-focus-indicator {
  height: 34px;
  width: 34px;
}

.mat-mdc-button-persistent-ripple {
  height: 34px;
  width: 34px;
  padding: 2px;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px;
}

.mat-mdc-form-field-flex {
  height: 2.5rem !important;
}

.mat-mdc-form-field-focus-overlay {
  height: 2.4rem !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
}
@media (min-width: 1200px) {
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 992px) {
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
}
@media (min-width: 992px) {
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .offset-md-3 {
    margin-left: 25%;
  }
}
@media (min-width: 768px) {
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
}
@media (min-width: 576px) {
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}
.offset-1 {
  margin-left: 8.333333%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.select-padrao {
  height: 34px;
  border-radius: 8px;
  background-color: #f7f7f7;
}

.input-group-text {
  height: 41px;
}

@media (min-width: 768px) {
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

@media (min-width: 768px) {
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
