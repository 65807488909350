@import "src/assets/styles/colors";

.icon-container {
  position: absolute;
  right: 30px;
  top: calc(50% - 10px);
}

.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader::after,
.loader::before {
  content: "";
  background: $branco;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: $azul-base $azul-base transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

/* loading para campos */
.bar {
  background: #e9ebee;
  height: 20px;
  border-radius: 0.25rem;
}

.bar-lg {
  background: #e9ebee;
  height: 34px;
  border-radius: 0.25rem;
}

.loading {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    display: block;
    background-color: #dddfe2;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: 1.5s loading-placeholder ease-in-out infinite;
  }
}

@keyframes loading-placeholder {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
