@import "src/assets/styles/colors";

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  //min-width: 880px;
}

tr.mat-mdc-header-row {
  height: 40px;
  background-color: #f9f9fb;
  font-weight: bold;
}

.mat-mdc-row > * {
  flex-shrink: 0;
  width: auto;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

th.mat-mdc-cell {
  color: #5f5f5f;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

tr.mat-mdc-row {
  height: 40px;
}

.mat-mdc-row.hovered {
  background: #eee;
}

.mat-mdc-row.highlighted {
  background: #999;
}

::ng-deep .mat-sort-header-sorted {
  color: $branco;
}

::ng-deep .mat-sort-header-arrow {
  color: $branco;
}

::ng-deep th .mat-checkbox .mat-checkbox-frame {
  border-color: $branco;
}

::ng-deep th .mat-checkbox-checked .mat-checkbox-background {
  background-color: $branco !important;
}

::ng-deep th .mat-checkbox-checked .mat-checkbox-frame {
  border-color: $branco !important;
}

::ng-deep th .mat-checkbox-mixedmark {
  background-color: $azul-base !important;
}

::ng-deep th .mat-checkbox-checkmark-path {
  stroke: $azul-base !important;
}

::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $branco !important;
}

::ng-deep td .mat-checkbox-checked .mat-checkbox-background {
  background-color: $azul-base !important;
}

::ng-deep .mat-footer-row,
.mat-header-row,
.mat-mdc-row,
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-bottom-color: #e0e0e0 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.mat-mdc-slide-toggle.mat-primary.mat-mdc-slide-toggle-checked
  .mdc-switch__track::after {
  background-color: rgba(103, 58, 183, 0.94) !important;
}

.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-color: #046fae !important;
}

.mat-mdc-radio-checked .mdc-radio__inner-circle {
  border-color: #046fae !important;
}

.mat-mdc-slide-toggle.mat-primary.mat-mdc-slide-toggle-checked
  .mdc-switch__shadow {
  background-color: #065c8d !important;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.mat-sort-header-container {
  color: #5f5f5f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.table {
  margin-bottom: 0.1rem !important;
}

.table td,
.table th {
  border: none;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 15px 5px 15px !important;
}

.table-hover > tbody > tr:hover {
  background-color: $azul-hover-grid !important;
  font-weight: bold;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.form-group {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (min-width: 992px) {
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.float-right {
  float: right !important;
}

@media (min-width: 576px) {
  .float-sm-right {
    float: right !important;
  }
}
